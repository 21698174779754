var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Pie_de_página)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('v-img',{attrs:{"src":_vm.Pie_de_página.imagen,"max-width":"100%"}},[_c('div',{staticClass:"fila-elementos"},[_c('div',{staticClass:"columna-logo"},[_c('v-img',{attrs:{"src":_vm.Pie_de_página.logo,"width":"300"}})],1),_c('div',{staticClass:"columna-rutas"},_vm._l((_vm.Pie_de_página.rutas_izquierda),function(ref,i){
var texto = ref.texto;
var ruta = ref.ruta;
return _c('div',{key:i,staticClass:"fila-ruta",attrs:{"marcado":_vm.ruta_actual===ruta}},[_c('div',{staticClass:"texto",attrs:{"marcado":_vm.ruta_actual===ruta}},[_c('h3',[_vm._v(_vm._s(texto)+" ")])])])}),0),_c('div',{staticClass:"columna-rutas",attrs:{"direccion":"derecha"}},_vm._l((_vm.Pie_de_página.rutas_derecha),function(ref,i){
var texto = ref.texto;
var ruta = ref.ruta;
return _c('div',{key:i,staticClass:"fila-ruta"},[_c('div',{staticClass:"texto",attrs:{"marcado":_vm.ruta_actual===ruta}},[_c('h3',[_vm._v(_vm._s(texto)+" ")])])])}),0),_c('div',{staticClass:"columna-contacto"},_vm._l((_vm.Pie_de_página.contacto),function(ref,i){
var icono = ref.icono;
var línea_1 = ref.línea_1;
var línea_2 = ref.línea_2;
var línea_3 = ref.línea_3;
return _c('div',{key:i,staticClass:"fila-contacto"},[_c('v-row',[_c('img',{staticClass:"mr-2",attrs:{"src":icono,"width":"30","indice":i}}),_c('h3',{staticClass:"white--text font-weight-light"},[_vm._v(_vm._s(línea_1))])]),_c('v-row',{staticClass:"ml-4"},[_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(línea_2))])]),_c('v-row',{staticClass:"ml-4"},[_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(línea_3))])])],1)}),0)])]),_c('v-footer',{staticClass:"align-items-center justify-center pt-3 pb-3"},[_c('strong',{staticClass:"font-weight-light grey--text"},[_vm._v(_vm._s((_vm.año + " - © Todos los derechos reservados")))])])],1),_c('div',{staticClass:"d-md-none"},[_c('v-img',{attrs:{"src":_vm.Pie_de_página.imagen,"height":"350px"}},[_c('v-img',{staticStyle:{"margin-top":"8%","margin-bottom":"7%"},attrs:{"src":_vm.Pie_de_página.logo,"width":"100%","height":"50px","contain":""}}),_vm._l((_vm.Pie_de_página.contacto),function(ref,i){
var icono = ref.icono;
var línea_1 = ref.línea_1;
var línea_2 = ref.línea_2;
return _c('v-col',{key:i,attrs:{"col":"10"}},[_c('v-row',{staticClass:"align-items-center justify-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":icono,"height":"20px","contain":"","indice":i}}),_c('h4',{staticClass:"white--text font-weight-light"},[_vm._v(_vm._s(línea_1))])]),_c('v-row',{staticClass:"align-items-center justify-center"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(línea_2))]),_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.línea_3))])])],1)}),_c('div',{staticClass:"columna-redes align-items-center justify-center"},_vm._l((_vm.Navegador.redes_sociales),function(ref,i){
var icono = ref.icono;
var enlace = ref.enlace;
return _c('div',{key:i,staticClass:"red-social justify-center align-center",on:{"click":function($event){return _vm.abrir_enlace(enlace)}}},[_c('img',{staticClass:"pointer ml-3 mr-3 mt-4",attrs:{"src":icono}})])}),0)],2),_c('v-footer',{staticClass:"align-items-center justify-center"},[_c('strong',{staticClass:"font-weight-light black--text",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s((_vm.año + " - © Todos los derechos reservados")))])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }