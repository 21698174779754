<template lang="pug">
div(v-if='Pie_de_página')
  //-PC
  .d-none.d-md-block
    v-img(:src='Pie_de_página.imagen' max-width='100%' )
      .fila-elementos
        .columna-logo
          v-img(:src='Pie_de_página.logo' width='300')
        .columna-rutas
          .fila-ruta(v-for='{texto, ruta}, i in Pie_de_página.rutas_izquierda' :key='i' :marcado='ruta_actual===ruta')
            .texto(:marcado='ruta_actual===ruta')
              h3 {{texto}} 
        .columna-rutas(direccion='derecha')
          .fila-ruta(v-for='{texto, ruta}, i in Pie_de_página.rutas_derecha' :key='i' )
            .texto(:marcado='ruta_actual===ruta')
              h3 {{texto}} 
        .columna-contacto
          .fila-contacto(v-for='{icono, línea_1, línea_2, línea_3}, i in Pie_de_página.contacto' :key='i')
              v-row
                  img(:src='icono' width='30' :indice='i').mr-2
                  h3.white--text.font-weight-light {{línea_1}}
              v-row.ml-4
                h3.white--text {{línea_2}}
              v-row.ml-4
                h3.white--text {{línea_3}}
    v-footer.align-items-center.justify-center.pt-3.pb-3
      strong.font-weight-light.grey--text {{`${año} - © Todos los derechos reservados`}}
  //-Móvil
  .d-md-none
    v-img(:src='Pie_de_página.imagen' height='350px')
      v-img(:src='Pie_de_página.logo' width='100%' height='50px' contain style='margin-top: 8%; margin-bottom: 7%')
      v-col(col=10 v-for='{icono, línea_1, línea_2}, i in Pie_de_página.contacto' :key='i')
        v-row.align-items-center.justify-center
          img(:src='icono'  height='20px' contain :indice='i').mr-2
          h4.white--text.font-weight-light {{línea_1}}
        v-row.align-items-center.justify-center
          p.white--text {{línea_2}}
          p.white--text {{línea_3}}

      //- Redes-sociales
      .columna-redes.align-items-center.justify-center
        .red-social(v-for='{icono, enlace}, i in Navegador.redes_sociales' :key='i' @click='abrir_enlace(enlace)').justify-center.align-center
          img(:src='icono').pointer.ml-3.mr-3.mt-4

    v-footer.align-items-center.justify-center
      strong.font-weight-light.black--text(style='font-size: 10px;') {{`${año} - © Todos los derechos reservados`}}
    
</template>
<script>
export default {
  data: ()=>({
    año: new Date().getFullYear()
  }),
  computed: {
    ruta_actual(){
      return this.$route.name
    },
   },
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
      }
    },
  }

</script>
<style lang="sass" scoped>
.columna-redes
  display: flex
  flex-direction: row
  .red-social
    img
      width: 23px
      cursor: pointer

.fila-elementos
  padding: 130px
  display: flex
  flex-direction: row
  align-items: center
  .columna-logo
    padding: 50px
    flex-direction: column
  .columna-rutas
    padding-top: 46px
    padding-left: 160px
    align-items: center
    justify-content: center
    &[direccion='derecha']
      padding-top: 8px
      padding-left: 130px
    .fila-ruta
      flex-direction: column
      padding-bottom: 10px
      .texto
        h3
          color: white
        &[marcado='true']
          border-bottom: 1px solid red
  .columna-contacto
    padding-top: 206px
    padding-left: 90px
    flex-direction: column
    .fila-contacto
      padding-bottom: 40px
      flex-direction: column
      img
        &[indice='0']
          height: 25px
          width: 25px
        &[indice='1']
          width: 18px

      
</style>